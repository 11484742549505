import * as React from 'react';
import { TabList, Tab, Popover, PopoverTrigger, PopoverSurface } from "@fluentui/react-components";
import { ChatSparkleFilled, PenSparkleFilled, SparkleFilled, BinderTriangleFilled } from "@fluentui/react-icons";


const TabBar = ({ activeTab, onTabSelect }) => {
    return (
        <div style={{ paddingBottom: "4px"}}>
                  <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <TabList
        selectedValue={activeTab}
        onTabSelect={onTabSelect}
        size="small"
        style={{ gap: "28px" }}
      >
        <Popover withArrow openOnHover appearance="brand" size="small" mouseLeaveDelay={50}>
        <PopoverTrigger>
          <Tab value="insightsAndSummary" icon={<SparkleFilled />}></Tab>
        </PopoverTrigger>
          <PopoverSurface tabIndex={-1} style={{padding: '4px 12px', fontWeight: 'bold', fontSize: '12px', borderRadius: '12px' }}>
            <div>
              Insights
            </div>
          </PopoverSurface>
        </Popover>
        <Popover withArrow openOnHover appearance="brand" size="small" mouseLeaveDelay={50}>
        <PopoverTrigger>
          <Tab value="chatTab" icon={<ChatSparkleFilled />}></Tab>
        </PopoverTrigger>
          <PopoverSurface tabIndex={-1} style={{padding: '4px 12px', fontWeight: 'bold', fontSize: '12px', borderRadius: '12px' }}>
            <div>
              Chat
            </div>
          </PopoverSurface>
        </Popover>
        <Popover withArrow openOnHover appearance="brand" size="small" mouseLeaveDelay={50}>
        <PopoverTrigger>
          <Tab value="draftAndClauses" icon={<PenSparkleFilled />}></Tab>
        </PopoverTrigger>
          <PopoverSurface tabIndex={-1} style={{padding: '4px 12px', fontWeight: 'bold', fontSize: '12px', borderRadius: '12px' }}>
            <div>
              Draft
            </div>
          </PopoverSurface>
        </Popover>
        <Popover withArrow openOnHover appearance="brand" size="small" mouseLeaveDelay={50}>
        <PopoverTrigger>
          <Tab value="adminTab" icon={<BinderTriangleFilled />}></Tab>
        </PopoverTrigger>
          <PopoverSurface tabIndex={-1} style={{padding: '4px 12px', fontWeight: 'bold', fontSize: '12px', borderRadius: '12px' }}>
            <div>
              Review
            </div>
          </PopoverSurface>
        </Popover>
      </TabList>
              </div>
        </div>
        );
};

export default TabBar;
