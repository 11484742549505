import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { Button, Input, MessageBar, MessageBarBody,  MessageBarActions, MessageBarTitle } from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { userEmail, setIsAuthenticated, setNeedPasswordChange } = useAuth();

  useEffect(() => {
  }, [userEmail]);

  // Define your API base URLs
const API_BASE_URL_DEVELOPMENT = 'https://thefuturenow.io/api';
const API_BASE_URL_PRODUCTION = 'https://app.draftdeep.com/api';

// Determine the base URL based on the environment
const API_BASE_URL = process.env.REACT_APP_API_ENV === 'production' ? API_BASE_URL_PRODUCTION : API_BASE_URL_DEVELOPMENT;

  const validatePassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        return "Passwords do not match.";
      }
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
      return "Password must contain both letters and numbers.";
    }
    return '';
  };

  

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const deviceId = localStorage.getItem('deviceId');
  
    // Validate the new password and confirmation password match and meet criteria
    const validationError = validatePassword(newPassword, confirmPassword);
  
    if (validationError) {
      setError(validationError);
      console.error("Password change validation error for:", userEmail, validationError);
      return;
    }
  
    try {
      // Combine the password change and trial start into a single API call
      const response = await axios.post(`${API_BASE_URL}/change-password`, {
        email: userEmail,
        newPassword,
        deviceId
      });
  
      if (response.status === 200) {
        // Handle success: password updated and trial started successfully
        console.log('Password updated and trial started successfully.');
        setIsAuthenticated(true);
        setNeedPasswordChange(false);
        setError(''); // Clear any previous errors
      } else {
        // Handle any non-200 responses
        setError('Failed to update password and start trial. Please try again.');
        console.error('Failed to update user status:', response.status, response.data?.message);
      }
    } catch (error) {
      // Handle exceptions during the API call
      setError('Change Password error: ' + (error.response?.data?.message || error.message));
      console.error('Change Password error for', userEmail, ':', error.response?.data?.message || error.message);
    }
  };
  
  


  return (
<div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#EDEBE9'
  }}>
    <h2>Change Password</h2>
    
    <form onSubmit={handleChangePassword} style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      maxWidth: '250px'
    }}>
        <Input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
          style={{ width: 'calc(100%)'}}
          required
        />
         <Input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm New Password"
          style={{ width: 'calc(100%)'}}
          required
        />
        <span>Must be 8 or more characters and contain at least 1 number</span>
        {error && <MessageBar key="error" intent="error" style={{ width: '100%', maxWidth: '250px', margin: '0 auto', marginTop: '10px' }}>
        <MessageBarBody>
          <MessageBarTitle>Error</MessageBarTitle>
          {error}
        </MessageBarBody>
        <MessageBarActions containerAction={
          <Button appearance="transparent" icon={<DismissRegular />} />
        }></MessageBarActions>
      </MessageBar>}
        <Button appearance="primary" style={{ width: '100%'}}type="submit">Change Password</Button>
      </form>
    </div>
  );
};

export default ChangePassword;