import App from "./components/App";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
 import { createDarkTheme, createLightTheme } from '@fluentui/react-components';
 import type { BrandVariants, Theme } from '@fluentui/react-components';
import * as React from "react";
import { createRoot } from "react-dom/client";
import axios from 'axios';

axios.defaults.withCredentials = true;


/* global Office, module, require */

initializeIcons();

const rootElement = document.getElementById("container");
const root = createRoot(rootElement);

const pactumTheme: BrandVariants = { 
    10: "#040108",
    20: "#1C1031",
    30: "#2A1856",
    40: "#332174",
    50: "#3C2B91",
    60: "#4536AB",
    70: "#4E43C3",
    80: "#5851D8",
    90: "#635FEA",
    100: "#706EFA",
    110: "#7F7FFF",
    120: "#9090FF",
    130: "#A0A0FF",
    140: "#B0B1FF",
    150: "#C1C2FF",
    160: "#D1D3FF"
  };
  
  
   const lightTheme: Theme = {
     ...createLightTheme(pactumTheme), 
  };
  
   const darkTheme: Theme = {
     ...createDarkTheme(pactumTheme), 
  };
   
  
   darkTheme.colorBrandForeground1 = pactumTheme[110];
   darkTheme.colorBrandForeground2 = pactumTheme[120];
 

  Office.onReady(() => {
  root.render(
      <FluentProvider theme={webLightTheme}>
        <App lightTheme={lightTheme} darkTheme={darkTheme}/>
      </FluentProvider>
  );
});

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root.render(NextApp);
  });
}
