import React, { createContext, useState, useContext } from 'react';

interface AuthContextType {
    isAuthenticated: boolean;
    setIsAuthenticated: (value: boolean) => void;
    needPasswordChange: boolean;
    setNeedPasswordChange: (value: boolean) => void;
    userEmail: string;
    setUserEmail: (email: string) => void;
  }
  
  const AuthContext = createContext<AuthContextType | null>(null);

// Provide the context to children components
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [needPasswordChange, setNeedPasswordChange] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    needPasswordChange,
    setNeedPasswordChange,
    userEmail,
    setUserEmail,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
