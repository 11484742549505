import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, MessageBar, MessageBarTitle, MessageBarBody,  MessageBarActions, ProgressBar, Textarea, Field, Input, InfoLabel, LabelProps, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, Popover, PopoverTrigger, PopoverSurface, Spinner } from "@fluentui/react-components";
import { Send16Filled, SparkleFilled, NotepadRegular, DismissRegular, ArrowDownRegular } from "@fluentui/react-icons";
import type { DropdownProps, ButtonProps } from "@fluentui/react-components";
import callApi from '../apiUtils';
import Container from "./Container";
import AuthContext from '../AuthContext'; // Update the path accordingly
import { EllipsisHorizontalCircleIcon, MagnifyingGlassIcon, ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline'


const ChatTab = ({ chatPrompt, setChatPrompt, chatLog, setChatLog, error, setError, loading, setLoading, selectedParty }) => {
    const { setIsAuthenticated } = useContext(AuthContext);
    const [showScrollDownButton, setShowScrollDownButton] = useState(false);
    const [isTextSelected, setIsTextSelected] = useState(false);

const inputRef = useRef(null);

useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []); // The empty array ensures this effect runs only once after the initial render  


const chatLogRef = useRef(null);

const checkScrollBottom = () => {
if (!chatLogRef.current) return;
const { scrollTop, scrollHeight, clientHeight } = chatLogRef.current;
if (scrollHeight - scrollTop === clientHeight) {
    setShowScrollDownButton(false);
} else {
    setShowScrollDownButton(true);
}
};

const scrollToBottom = () => {
    if (chatLogRef.current) {
        chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
};

const checkTextSelection = async () => {
  try {
      const selectedText = await Word.run(async (context) => {
          const selection = context.document.getSelection();
          selection.load("text");
          await context.sync();
          return selection.text;
      });
      setIsTextSelected(selectedText.trim().length > 0);
  } catch (error) {
      console.error("Error checking text selection:", error);
  }
};
  
const MessageButton: React.FC<ButtonProps> = (props) => {
    return (
        <Popover withArrow openOnHover size="small" appearance="brand" mouseLeaveDelay={50}>
        <PopoverTrigger disableButtonEnhancement>
            <Button
            {...props}
            appearance="primary"
            icon={<Send16Filled />}
            size="small"
            />
        </PopoverTrigger>
        <PopoverSurface tabIndex={-1} style={{padding: '4px 10px', fontWeight: 'bold', fontSize: '10px', borderRadius: '10px' }}>
          <div>Send Message</div>
        </PopoverSurface>
      </Popover>

    );
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendMessage();
        }
    };

    function convertHtmlToMarkdown(html) {
      // Remove script tags and content
      html = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
  
      // Remove all attributes from tags (e.g., class, style) to clean up the HTML
      // Note: This is a simple approach and may not work for all cases, especially with nested quotes.
      html = html.replace(/<(\w+)(\s+[^>]*)?>/g, '<$1>');
  
      // Convert basic HTML tags to their Markdown equivalents
      let markdown = html
          .replace(/<b>(.*?)<\/b>/g, '**$1**')
          .replace(/<strong>(.*?)<\/strong>/g, '**$1**')
          .replace(/<i>(.*?)<\/i>/g, '*$1*')
          .replace(/<em>(.*?)<\/em>/g, '*$1*') 
          .replace(/<s>(.*?)<\/s>/g, '~~$1~~') 
          .replace(/<del>(.*?)<\/del>/g, '~~$1~~'); 
  
      // Handling line breaks and paragraphs
      markdown = markdown
          .replace(/<br\s*\/?>/g, '\n') 
          .replace(/<p>(.*?)<\/p>/g, '\n$1\n');

      markdown = markdown.replace(/<\/?[^>]+(>|$)/g, "");
  
      return markdown;
  }  

const onSummarize = async () => {
    setError("");
    setLoading(true);
    setChatLog(log => [...log, { sender: 'user', text: "Summarizing document..." }]);

    try {
        const body = await Word.run(async (context) => {
            const body = context.document.body;
            body.load("text");
            await context.sync();
            return body.text;
        });

        const data = await callApi('summarize-document', 'POST', { documentText: body }, setIsAuthenticated);
        if (data.success) {
            setChatLog(log => [...log, { sender: 'ai', text: data.summary }]);
        } else {
            setError("Error summarizing document");
        }
    } catch (error) {
        setError("Failed to summarize document");
    } finally {
        setLoading(false);
    }
};

const onExplainSelection = async () => {
    setError("");
    setLoading(true);

    try {
        const selectedText = await Word.run(async (context) => {
        const selection = context.document.getSelection();
        selection.load("text");
        await context.sync();
        return selection.text;
      });

      if (selectedText.trim().length === 0) {
        throw new Error("Please select a section of the document to explain.");
      }
  
      setChatLog(log => [...log, { sender: 'user', text: "Decoding section..." }]);

      const data = await callApi('explain-section', 'POST', { documentText: selectedText }, setIsAuthenticated);

        if (data.success) {
            setChatLog(log => [...log, { sender: 'ai', text: data.explanation }]);
        } else {
            setError("Error decoding section");
        }
    } catch (error) {
        setError(error.message);
    } finally {
        setLoading(false);
    }
};

const sendMessage = async () => {
  if (!chatPrompt.trim()) {
      setError("Please type a message");
      return;
  }

  setError("");
  setLoading(true);
  setChatLog(log => [...log, { sender: 'user', text: chatPrompt }]);
  setChatPrompt(''); // Clear the input field

  try {
      const documentHTML = await Word.run(async (context) => {
          const body = context.document.body;
          const bodyHTML = body.getHtml(); // Initiates loading of HTML
          await context.sync(); // Ensures that the HTML loading is completed
          return bodyHTML.value; // Assuming the structure includes a value field containing the HTML
      });

      // Now `documentHTML` contains the document's HTML, you would convert this to Markdown next

      // Placeholder for conversion function
      const markdown = convertHtmlToMarkdown(documentHTML);

      const requestBody = {
          message: chatPrompt,
          documentText: markdown, // Send the converted Markdown
          selectedParty
      };

      const data = await callApi('chat', 'POST', requestBody, setIsAuthenticated);

      if (data.success) {
          setChatLog(log => [...log, { sender: 'ai', text: data.response }]);
      } else {
          setError("Error getting response from AI");
      }
  } catch (error) {
      console.error(error);
      setError("Failed to send message");
  } finally {
      setLoading(false);
  }
};


const PlaceholderContent = () => (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 200px)', 
        overflow: 'auto',
        maxWidth: '70%',
        margin: 'auto'
    }}>
    {loading ? (
    <Spinner labelPosition="below" appearance="primary" label="Generating..." />
      ) : (
        <>
        <img src='https://storage.googleapis.com/draftdeep/assets/logo.svg' alt="DraftDeep" style={{ width: '50px', marginBottom: '15px', color: '#6E738B', opacity: '0.8' }} />
        <span style={{ fontSize: '15px', fontWeight: '600', marginBottom: '10px', color: '#6D738A', }}>Give an instruction or ask a question below.</span>
        </>
      )}
    </div>
);


return (
<>
    <Container>
    <div>
    {chatLog.length === 0 ? (
        <PlaceholderContent />
    ) : (
    <div ref={chatLogRef} onScroll={checkScrollBottom} style={{ overflowY: 'auto', marginBottom: '30px', color: 'black' }}>
{chatLog.map((entry, index) => (
    <div 
      key={index} 
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: entry.sender === 'user' ? 'flex-end' : 'flex-start',
        alignItems: entry.sender === 'user' ? 'flex-end' : 'flex-start', // Align items to the end or start based on the sender
    }}>
        <div
          style={{
            display: 'inline-block', // Changed from 'flex' to 'inline-block'
            maxWidth: '85%',
            textAlign: entry.sender === 'user' ? 'right' : 'left',
            margin: '4px 0px',
            padding: '8px 12px',
            fontSize: '13px',
            borderRadius: entry.sender === 'user' ? '12px 4px 12px 12px' : '4px 12px 12px',
            color: entry.sender === 'user' ? 'white' : 'black',
            backgroundColor: entry.sender === 'user' ? '#605BE5' : '#F1F1F1',
            wordBreak: 'break-word',
          }}
        >
          {/* Convert newline characters into <br /> elements within the same chat bubble */}
          {entry.text.split('\n').map((line, lineIndex) => (
              <React.Fragment key={lineIndex}>
                  {line}
                  {lineIndex < entry.text.split('\n').length - 1 && <br />}
              </React.Fragment>
          ))}
        </div>
    </div>
))}



        <Container>
        {loading && <Field validationMessage="Loading response..." validationState="none" validationMessageIcon={<SparkleFilled />}><ProgressBar /></Field>}
          {error && <MessageBar key="error" intent="error">
          <MessageBarBody><MessageBarTitle>Error</MessageBarTitle>{error}</MessageBarBody><MessageBarActions
      containerAction={
        <Button appearance="transparent" icon={<DismissRegular />} />
      }
    ></MessageBarActions></MessageBar>}
        </Container>
    </div>
    )}
    </div>

{showScrollDownButton && (
        <Button
            icon={<ArrowDownRegular />}
            style={{
                position: 'fixed',
                right: '20px',
                bottom: '65px', // Adjust this value so the button appears just above the fixed background
                zIndex: 101, // Ensure zIndex is higher than the chat input area
                borderRadius: '50%',
                minWidth: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClick={scrollToBottom}
        />
        )}

{/* Fixed white background covering the bottom of the screen */}
<div style={{       
  position: 'fixed',
  zIndex: '98', 
  bottom: 0, 
  left: 0,
  right: 0,
  background: '#FFFFFF',
  paddingBottom: '8px', 
}}> 
  {/* Chat input bar with specific styling inside the white background */}
  <div style={{       
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    margin: '0 8px',
    background: '#F6F7F8',
    borderRadius: '16px',
    border: '1px solid #dadee2',
    zIndex: '99',
  }}>
    <div style={{ display: 'flex', alignItems: 'center', width: '100%'}}>
      <Input
        style={{ flexGrow: 1 }}
        contentAfter={<MessageButton aria-label="Message" onClick={sendMessage}/>}
        appearance="underline"
        placeholder="Type your message here"
        value={chatPrompt}
        onChange={(_, data) => setChatPrompt(data.value || "")}
        onKeyDown={handleKeyPress}
        ref={inputRef}
      />
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Button appearance="subtle" onClick={checkTextSelection} icon={<EllipsisHorizontalCircleIcon/>}></Button>
        </MenuTrigger>
        <MenuPopover style={{ borderRadius: '10px' }}>
          <MenuList>
            <MenuItem onClick={onSummarize}><MagnifyingGlassIcon style={{height: '14px', marginRight: '4px'}}/>Summarize document</MenuItem>
            <MenuItem onClick={onExplainSelection} disabled={!isTextSelected}><ChatBubbleLeftEllipsisIcon style={{height:'14px', marginRight: '4px'}}/>Explain selection</MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  </div>
</div>

    </Container>

</>
);
};

export default ChatTab;