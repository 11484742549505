// apiUtils.js
import axios from 'axios';

axios.defaults.withCredentials = true;

// Define your API base URLs
const API_BASE_URL_DEVELOPMENT = 'https://thefuturenow.io/api';
const API_BASE_URL_PRODUCTION = 'https://app.draftdeep.com/api';

// Determine the base URL based on the environment
const API_BASE_URL = process.env.REACT_APP_API_ENV === 'production' ? API_BASE_URL_PRODUCTION : API_BASE_URL_DEVELOPMENT;

// Modify the refreshToken function to accept setIsAuthenticated
const refreshToken = async (setIsAuthenticated) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth-check`);
      if (response.status === 200 && response.data.accessToken) {
        return true;
      }
      setIsAuthenticated(false);
      return false;
    } catch (error) {
      setIsAuthenticated(false);
      return false;
    }
  };

// Modify callApi to accept and pass setIsAuthenticated
const callApi = async (endpoint, method = 'GET', body = null, setIsAuthenticated) => {
    try {
      const response = await axios({
        method: method,
        url: `${API_BASE_URL}/${endpoint}`,
        data: body,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refreshed = await refreshToken(setIsAuthenticated);
        if (refreshed) {
          return axios({
            method: method,
            url: `${API_BASE_URL}/${endpoint}`,
            data: body
          }).then(response => response.data);
        }
      }
      throw error;
    }
  };

export default callApi;
