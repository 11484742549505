import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Button, MessageBar, MessageBarBody,  MessageBarActions, MessageBarTitle, ProgressBar, Textarea, Field, Input, InfoLabel, LabelProps, Popover, PopoverTrigger, PopoverSurface, Spinner} from "@fluentui/react-components";
import { Send16Filled, Copy16Regular, SparkleFilled, PenSparkleFilled, AttachTextRegular, DismissRegular, LightbulbRegular, DocumentText16Regular, Dismiss12Regular, ArrowImport16Regular } from "@fluentui/react-icons";
import type { DropdownProps, ButtonProps } from "@fluentui/react-components";
import Container from "./Container";
import * as pdfjsLib from 'pdfjs-dist';
import callApi from '../apiUtils';
import AuthContext from '../AuthContext'; // Update the path accordingly



const ClauseGenerator = ({ prompt, setPrompt, generatedTextFromGenerate, setGeneratedTextFromGenerate, generatedTextFromClausify, setGeneratedTextFromClausify, onInsert, onCopy, error, setError, loading, setLoading, precedence, setPrecedence, fileName, setFileName, selectedParty }) => {

const { setIsAuthenticated } = useContext(AuthContext);

const inputRef = useRef(null);

  // Define your API base URLs
  const API_BASE_URL_DEVELOPMENT = 'https://thefuturenow.io/api';
  const API_BASE_URL_PRODUCTION = 'https://app.draftdeep.com/api';
  
  // Determine the base URL based on the environment
  const API_BASE_URL = process.env.REACT_APP_API_ENV === 'production' ? API_BASE_URL_PRODUCTION : API_BASE_URL_DEVELOPMENT;

useEffect(() => {
  if (inputRef.current) {
    inputRef.current.focus();
  }
}, []); // The empty array ensures this effect runs only once after the initial render

const handleEnterPress = (event) => {
  if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action to avoid form submission or line breaks
      onClick();
  }
};

const MessageButton: React.FC<ButtonProps> = (props) => {
  return (
    <Popover withArrow openOnHover size="small" appearance="brand" mouseLeaveDelay={50}>
    <PopoverTrigger disableButtonEnhancement>
        <Button
          {...props}
          appearance="primary"
          icon={<Send16Filled />}
          size="small"
          />
    </PopoverTrigger>
    <PopoverSurface tabIndex={-1} style={{padding: '4px 8px', fontWeight: 'bold', fontSize: '10px', borderRadius: '8px' }}>
      <div>Send Clause</div>
    </PopoverSurface>
  </Popover>
  );
  };

const stripJSONHeaderFooter = (text) => {
    return text.replace(/^```json|```$/g, '');
    };
    
    const parseJSON = (jsonString) => {
    try {
        const strippedString = stripJSONHeaderFooter(jsonString);
        if (/^[\[\{]/.test(strippedString) && /[\]\}]$/.test(strippedString)) {
        return JSON.parse(strippedString);
        } else {
        console.error("Not a valid JSON string:", strippedString);
        return null;
        }
    } catch (error) {
        console.error("Error parsing JSON:", error);
        return null;
    }
    };

  const onAttachPrecedence = () => {
  document.getElementById('fileInput').click();
};

const handleFileChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
    setFileName(file.name);

    if (file.type === 'application/pdf') {
      const reader = new FileReader();

      reader.onload = async (e) => {
        if (e.target.result && e.target.result instanceof ArrayBuffer) {
          const typedArray = new Uint8Array(e.target.result);
      
          pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
          const loadingTask = pdfjsLib.getDocument({ data: typedArray });
          let textContent = '';
      
          try {
            const pdf = await loadingTask.promise;
            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
              const page = await pdf.getPage(pageNum);
              const text = await page.getTextContent();
              textContent += text.items
                .map(item => {
                  // Ensure the item is a TextItem before accessing 'str'
                  if ("str" in item) {
                    return item.str;
                  }
                  return '';
                })
                .join(' ');
            }
            setPrecedence(textContent);
          } catch (error) {
            console.error('Error reading PDF:', error);
          }
        }
      };
      
      reader.readAsArrayBuffer(file);
    } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      // Handle DOCX file with server
      const formData = new FormData();
      formData.append('file', file);
      fetch(`${API_BASE_URL}/upload-file`, {
        method: 'POST',
        body: formData,
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Server responded with an error!');
        }
        return response.text();
      })
      .then(text => {
        try {
          const data = JSON.parse(text);
          if (data.success) {
            setPrecedence(data.text);
            setFileName(file.name);
          } else {
            console.error(data.message);
          }
        } catch (error) {
          console.error("Response is not valid JSON:", text);
        }
      })
      .catch(error => {
        console.error("Error uploading DOCX file:", error);
      });
    } else {
      console.error('Unsupported file type');
    }
  }
};

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};


const onClick = async () => {
  if (!prompt.trim()) {
      setError("Please type in a clause to generate");
      return;
  }

  setError("");
  setLoading(true);

  try {
    const body = await Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();
      return body.text;
    });

    // Prepare the request body, including precedence if available
    const requestBody = {
      prompt: prompt,
      documentText: body,
      selectedParty,
      ...(precedence && { precedence }) // Include precedence if available
  };    

    const requestBodyString = JSON.stringify({ requestBody });

    const data = await callApi('generate-clause', 'POST', requestBody, setIsAuthenticated);

    if (data.success) {
      setGeneratedTextFromGenerate(JSON.stringify(data.clauses));
      setPrompt('');
    } else {
      setError("Error generating clauses");
    }
  } catch (error) {
    setError("Failed to generate clauses");
  } finally {
    setLoading(false);
  }
};  

  const onClausify = async () => {
    setError("");
    setLoading(true);
  
    try {
      const body = await Word.run(async (context) => {
        const body = context.document.body;
        body.load("text");
        await context.sync();
        return body.text;
      });
  
      const data = await callApi('recommend-clauses', 'POST', { documentText: body, selectedParty }, setIsAuthenticated);

      if (data.success) {
        setGeneratedTextFromClausify(JSON.stringify(data.recommendedClauses));
      } else {
        setError("Error recommending clauses");
      }
    } catch (error) {
      setError("Failed to recommend clauses");
    } finally {
      setLoading(false);
    }
  };
  

  const renderClausifyContent = () => {
    if (generatedTextFromClausify) {
      const parsedData = parseJSON(generatedTextFromClausify);
      if (!parsedData) return <p>Error displaying clauses</p>;

    return (
      <>
        {parsedData.clauses.map((clause, index) => (
          <div key={index} style={{ 
            background: '#F1F1F5', 
            borderRadius: '12px', 
            marginBottom: '28px', 
            padding: '16px 20px',
            // boxShadow: 'rgba(100, 100, 111, 0.02) 0px 7px 29px 0px'
          }}>
            <div style={{ 
              fontWeight: 'bold', 
              fontSize: '12px',
              marginBottom: '4px'
            }}>
              {clause.title}
            </div>

            <div style={{ 
              width: "100%", 
              fontSize: '12px',
            }}>
              {clause.purpose}
            </div>

            <div style={{ 
              fontSize: '12px', 
              color: '#3D3D3D',
              margin: '12px 0px', // Add some bottom margin for spacing
              fontStyle: 'italic'
            }}>
              "{clause.content}"
            </div>

            <div style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}>
              <Button icon={<Copy16Regular />} appearance="subtle" onClick={() => onCopy(`${clause.title}\n\n${clause.content}`)} style={{ 
                marginRight: '5px', 
                fontWeight: 'semibold', 
                fontSize: '12px',
                maxWidth: '78px'
              }}>Copy
              </Button>
              <Button icon={<ArrowImport16Regular/>} onClick={() => onInsert(`${clause.title}\n\n${clause.content}` )} 
              style={{
                fontWeight: 'semibold', 
                fontSize: '12px',
                maxWidth: '78px'
              }}>
                Insert
              </Button>
            </div>
          </div>
        ))}
      </>
    );
  } else {
    return null;
  }
};

const renderClauses = () => {
  try {
    const responseData = parseJSON(generatedTextFromGenerate);
    if (!responseData || !Array.isArray(responseData.clauses)) {
      return <p>Error displaying clauses</p>;
    }

    return responseData.clauses.map((clause, index) => (
      <div key={index} style={{ 
        background: '#F1F1F5', 
        borderRadius: '12px', 
        marginBottom: '28px', 
        // boxShadow: 'rgba(100, 100, 111, 0.02) 0px 7px 29px 0px', 
        padding: '16px 20px', 
      }}>
        {/* Clause Title */}
        <div style={{ 
          fontWeight: 'bold', 
          fontSize: '12px', 
        }}>
          {clause.title}
        </div>

        {/* Clause Content */}
        <div style={{ 
          fontSize: '12px', 
          color: '#3D3D3D', 
          margin: '12px 0px',
          fontStyle: 'italic'
        }}>
          "{clause.content}"
        </div>

        {/* Action Buttons */}
        <div style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        }}>
          <Button 
            icon={<Copy16Regular />} 
            appearance="subtle"
            onClick={() => onCopy(`${clause.title}\n\n${clause.content}`)} 
            style={{ 
              marginRight: "8px", 
              fontWeight: 'semibold', 
              fontSize: '12px',
              maxWidth: '78px' 
            }}>
            Copy
          </Button>
          <Button  
            icon={<ArrowImport16Regular/>} 
            onClick={() => onInsert(`${clause.title}\n\n${clause.content}` )} 
            style={{
              fontWeight: 'semibold', 
              fontSize: '12px',
              maxWidth: '78px'
            }}>
            Insert
          </Button>
        </div>
      </div>
    ));
  } catch (error) {
    console.error("Error parsing clauses:", error);
    return <p>Error displaying clauses</p>;
  }
};

  const PlaceholderContent = () => {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 200px)', 
        overflow: 'auto'
      }}>
        {error && (
      <MessageBar key="error" intent="error" style={{ marginTop: '12px', maxWidth: '95%' }}>
        <MessageBarBody><MessageBarTitle>Error</MessageBarTitle>{error}</MessageBarBody>
        <MessageBarActions containerAction={
          <Button appearance="transparent" icon={<DismissRegular />} onClick={() => setError('')} />
        }></MessageBarActions>
      </MessageBar>
    )}
    {loading ? (
        <Spinner appearance="primary" labelPosition="below" label="Generating..."/>
      ) : (
      <>
        <img src='https://storage.googleapis.com/draftdeep/assets/logo.svg' alt="Logo" style={{ width: '50px', marginBottom: '15px', color: '#6E738B', opacity: '0.8' }} />
        <span style={{ fontSize: '15px', marginBottom: '10px', fontWeight: '600', color: '#6D738A' }}>Generate a clause below.</span>
      </>
    )} 
    </div>
  );
  };

return (
    <>
    <Container>
    <div style={{       
      position: 'fixed',
      zIndex: '98', 
      bottom: 0, 
      left: 0,
      right: 0,
      background: '#FFFFFF', 
      paddingBottom: '8px', 
    }}> 
      <div style={{       
        background: '#F6F7F8',
        borderRadius: '16px',
        margin: '0 8px',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: '99',
        border: '1px solid #dadee2',
      }}>
        {fileName && (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'left',
                background: '#eceef0',
                padding: '2px 4px', 
                borderRadius: '8px',
                marginBottom: '4px', 
            }}>
              <DocumentText16Regular style={{ marginRight: '3px'}} />
              <div style={{maxWidth: '120px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', overflow: 'hidden'}}>
              <span>{truncateString(fileName, 20)}</span>
              </div>
              <Button 
                icon={<Dismiss12Regular />} 
                style={{ marginLeft: '3px', border: 'none', padding: 0, minWidth: 'auto' }} 
                appearance="transparent" 
                onClick={() => {
                  setFileName('');
                  setPrecedence('');
                }} 
              />
            </div>
        )}
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}>
          <Popover withArrow openOnHover size="small" appearance="brand" mouseLeaveDelay={50}>
            <PopoverTrigger disableButtonEnhancement>
              <Button onClick={onAttachPrecedence} icon={<AttachTextRegular />} appearance="transparent" size="small"/>
            </PopoverTrigger>
            <PopoverSurface tabIndex={-1} style={{padding: '4px 8px', fontWeight: 'bold', fontSize: '10px', borderRadius: '8px' }}>
              <div>Attach Precedent</div>
            </PopoverSurface>
          </Popover>
          <input type="file" id="fileInput" style={{ display: 'none' }} accept=".docx, .pdf" onChange={handleFileChange}/>
          <Input
              placeholder="Draft clause"
              appearance="underline"
              contentAfter={<MessageButton aria-label="Generate" onClick={onClick}/>}
              style={{ flexGrow: 1 }}
              value={prompt}
              onChange={(_, data) => setPrompt(data.value || "")}
              onKeyDown={handleEnterPress}
              ref={inputRef}
          />
           <Popover withArrow openOnHover size="small" appearance="brand" mouseLeaveDelay={50}>
            <PopoverTrigger disableButtonEnhancement>
                <Button onClick={onClausify} icon={<LightbulbRegular/>} appearance="subtle"/>
            </PopoverTrigger>
            <PopoverSurface tabIndex={-1} style={{padding: '4px 8px', fontWeight: 'bold', fontSize: '10px', borderRadius: '10px' }}>
              <div>Recommend</div>
            </PopoverSurface>
          </Popover>
        </div>
      </div>
    </div>

<div style={{ marginBottom: '40px', color: 'black' }}>
{!generatedTextFromGenerate && !generatedTextFromClausify ? (
    <PlaceholderContent />
  ) : (
    <div>
      <div style={{ marginBottom: '5px'}}>
      {loading && <Field validationMessage="Generating text..." validationState="none" validationMessageIcon={<SparkleFilled />}><ProgressBar /></Field>}

      {error && <MessageBar key="error" intent="error">
      <MessageBarBody><MessageBarTitle>Error</MessageBarTitle>{error}</MessageBarBody>
        <MessageBarActions containerAction={ 
        <Button appearance="transparent" icon={<DismissRegular />} onClick={() => setError('')}/>
      }></MessageBarActions></MessageBar>}
    </div>

      {generatedTextFromGenerate && (
        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '20px' }}>Generated Clauses</div>
      )}

      {generatedTextFromGenerate && (
        <>
          {renderClauses()}
        </>
        )}

      {generatedTextFromClausify && (
        <>
          <div style={{ display: "flex", alignItems: "center", marginBottom: '20px' }}> 
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Recommended Clauses</span>
          </div>
      </>
      )}

      {renderClausifyContent()}
      </div>
  )}
      </div>
    </Container>
      </>
    );
};

export default ClauseGenerator;



