import * as React from 'react';
import { useEffect, useState } from 'react';
import { ToggleButton, Button, Accordion, AccordionItem, AccordionHeader, AccordionPanel, Field, ProgressBar, MessageBar, MessageBarBody,MessageBarTitle, Popover, PopoverTrigger, PopoverSurface } from "@fluentui/react-components";
import { SparkleFilled, BookGlobeFilled, BookNumberFilled, CalendarDayFilled, DocumentPageBreakFilled, MoneyFilled, ArrowSync16Filled, ArrowForward16Regular, ChevronUp16Regular, ChevronDown16Regular, Delete12Regular } from "@fluentui/react-icons";
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import Container from "./Container";
import "../styles.css"


const AdminTab = ({ showDisclaimer, setShowDisclaimer, error, setError, loading, setLoading, referencesData, setReferencesData, showDefinitions, setShowDefinitions, showReferences, setShowReferences, showDates, setShowDates, showAmounts, setShowAmounts, datesData, setDatesData, definitionsData, setDefinitionsData, amountsData, setAmountsData, placeholdersData, setPlaceholdersData}) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalOccurrences, setTotalOccurrences] = useState(0);
  const [openPopovers, setOpenPopovers] = useState({});
  const [currentType, setCurrentType] = useState('Date');

  const navigateToFirstInstance = async (text) => {
    await Word.run(async (context) => {
        // Perform the search
        const searchResults = context.document.body.search(text, { matchCase: true, matchWholeWord: true });
        context.load(searchResults, 'items');
        await context.sync();

        // Navigate to the first occurrence if it exists
        if (searchResults.items.length > 0) {
            searchResults.items[0].select();
            await context.sync();
        }
    }).catch(error => {
        console.error("Error in navigateToFirstInstance:", error);
    });
};

  const togglePopover = async (index, dataType) => {  
    // Updating the openPopovers state
    const updatedPopovers = { ...Object.fromEntries(Object.keys(openPopovers).map(key => [key, false])), [index]: !openPopovers[index] };
    setOpenPopovers(updatedPopovers);
  
    console.log("Updated openPopovers:", updatedPopovers);
  
    let currentItemText;
    switch (dataType) {
      case 'definitions':
        currentItemText = definitionsData[index]?.text;
        break;
      case 'references':
        currentItemText = referencesData[index]?.text;
        break;
      // Add cases for other data types if needed
      default:
        currentItemText = null;
    }
  
    if (currentItemText) {
      await updateTotalOccurrences(currentItemText);
      navigateToFirstInstance(currentItemText);
    } else {
    }
  
    setCurrentIndex(0);
  };  

const toggleDateAmountPopover = async (index, itemData, setCurrentType) => {
  setOpenPopovers({ ...Object.fromEntries(Object.keys(openPopovers).map(key => [key, false])), [index]: !openPopovers[index] });

  setCurrentType(itemData.type);

  if (itemData.indices.length > 1) {
    setCurrentIndex(0);
  }
};

const updateTotalOccurrences = async (text) => {
  await Word.run(async (context) => {
    // Logging the text that will be searched

    const searchResults = context.document.body.search(text, { matchCase: true, matchWholeWord: true });
    context.load(searchResults, 'items');
    await context.sync();

    const total = searchResults.items.length;

    setTotalOccurrences(total);
  }).catch(error => {
    console.error("Error in updateTotalOccurrences: " + error);
  });
};

const findNext = async (text, _dataType) => {
  await Word.run(async (context) => {
    const searchResults = context.document.body.search(text, { matchCase: true, matchWholeWord: true });
    context.load(searchResults, 'items');
    await context.sync();

    if (searchResults.items.length > 0) {
      const newIndex = (currentIndex + 1) % searchResults.items.length;
      setCurrentIndex(newIndex);
      searchResults.items[newIndex].select('Select');
      await context.sync();
    } else {
      console.error("No more occurrences found.");
    }
  }).catch(error => {
    console.error("Error in findNext: " + error);
  });
};

const findPrevious = async (text, _dataType) => {
  await Word.run(async (context) => {
    const searchResults = context.document.body.search(text, { matchCase: true, matchWholeWord: true });
    context.load(searchResults, 'items');
    await context.sync();

    if (searchResults.items.length > 0) {
      const newIndex = (currentIndex === 0) ? searchResults.items.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      searchResults.items[newIndex].select('Select');
      await context.sync();
    } else {
      console.error("No more occurrences found.");
    }
  }).catch(error => {
    console.error("Error in findPrevious: " + error);
  });
};

const renderCounter = () => {
  if (totalOccurrences > 0) {
    return `${currentIndex + 1} of ${totalOccurrences}`;
  }
  return "";
};

const findCrossReferences = (text) => {
  const crossReferencePattern = /(?:Section|Sections|Clause|Clauses|Paragraph|Paragraphs|Subsection|Subsections)\s(\d+)((,\s*\d+)*(\s*and\s*\d+)?)/g;
  let match;
  let matches = [];

  while ((match = crossReferencePattern.exec(text)) !== null) {
    // Extract individual section numbers
    const sectionNumbers = match[0].match(/\d+/g);
    if (sectionNumbers) {
      sectionNumbers.forEach(number => {
        matches.push(number); // Add only the number to the matches array
      });
    }
  }

  return matches;
};

const processReferences = (text) => {
  try {
    const crossReferences = findCrossReferences(text);
    const references = crossReferences.map(refNumber => ({
      type: "Reference",
      text: refNumber,
    }));

    return references; // Return array of references directly
  } catch (error) {
    console.error("Error processing references: ", error);
    return []; // Return an empty array in case of error
  }
};

const fetchCrossReferences = async () => {
  setLoading(true);
  setError("");

  try {
    const body = await Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();
      return body.text;
    });

    const referencesArray = processReferences(body);
    setReferencesData(referencesArray); // Set state with the array
  } catch (error) {
    setError(`Failed to fetch cross references: ${error.message}`);
  } finally {
    setLoading(false);
  }
};


const detectDefinitions = (text) => {
  const definitions = new Set();

  // Regex for capitalized words followed by "means"
  const capitalizedWordsMeansRegex = /([A-Z][a-z.]*([-\s][A-Z][a-z.]*)*)(?=.*\bmeans\b)/gm;
  let match;
  while ((match = capitalizedWordsMeansRegex.exec(text)) !== null) {
    definitions.add(match[1].trim());

  }

  // Updated Regex: Captures phrases within quotes where only the first word is capitalized
  const quotesWithCapitalizedWordsRegex = /["“”]\b([A-Z][\w]*((?:\s+[a-zA-Z][\w]*)*))\b["“”]/g;
  while ((match = quotesWithCapitalizedWordsRegex.exec(text)) !== null) {
    definitions.add(match[1].trim());
  }
  // Filtering logic remains the same
  const filteredDefinitions = Array.from(definitions).filter(def => {
    return !Array.from(definitions).some(longerDef => {
      return longerDef !== def && (longerDef as string).includes(def as string);
    });
  });
  return filteredDefinitions.map(text => ({ type: "Definition", text }));
};


const fetchDefinitions = async () => {
  setLoading(true);
  setError("");

  try {
    const body = await Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();
      return body.text;
    });

    const detectedDefinitions = detectDefinitions(body);
    setDefinitionsData(detectedDefinitions);
  } catch (error) {
    setError(`Failed to fetch definitions: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

const dismissDefinition = (definitionText) => {
  setDefinitionsData(definitionsData.filter(def => def.text !== definitionText));
};

const detectDates = (text) => {
  const datePatterns = [
    /\b(?:0?[1-9]|1[0-2])\/(?:0?[1-9]|[12][0-9]|3[01])\/(?:19|20)?\d{2}\b/g, // MM/DD/YYYY
    /\b(?:0?[1-9]|[12][0-9]|3[01])\/(?:0?[1-9]|1[0-2])\/(?:19|20)?\d{2}\b/g, // DD/MM/YYYY
    /\b(?:0?[1-9]|[12][0-9]|3[01])\s(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4}\b/g, // DD Month YYYY
    /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s(?:0?[1-9]|[12][0-9]|3[01]),?\s\d{4}\b/g, // Month DD, YYYY
    /\b\d{4},?\s(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s(?:0?[1-9]|[12][0-9]|3[01])\b/g, // YYYY Month DD
    /\b(?:0?[1-9]|[12][0-9]|3[01])((?:st|nd|rd|th)?)\s(?:of\s)?(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s\d{4}\b/g // DDth of Month YYYY
  ];

  const datesMap = new Map();
  datePatterns.forEach(pattern => {
    let match;
    while ((match = pattern.exec(text)) !== null) {
      if (!datesMap.has(match[0])) {
        datesMap.set(match[0], {
          type: "Date",
          text: match[0],
          indices: [match.index]
        });
      } else {
        datesMap.get(match[0]).indices.push(match.index);
      }
    }
  });

  return Array.from(datesMap.values());
};

const fetchDates = async () => {
  setLoading(true);
  setError("");

  try {
    const body = await Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();
      return body.text;
    });

    const detectedDates = detectDates(body); // Detect dates
    setDatesData(detectedDates); // Update the datesData state

  } catch (error) {
    setError(`Failed to process dates: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

const onDateClick = async (index, item) => {
  toggleDateAmountPopover(index, item, setCurrentType);
  await navigateToInstance(item.text, 0);
};

const dismissDate = (dateText) => {
  setDatesData(datesData.filter(date => date.text !== dateText));
};

const detectAmounts = (text) => {
  const currencySymbols = "\\$|€|£|¥|₩|₪|₱|₲|₴|₸|₮|₺|₼|₽|Bs|ریال|₫|₭|₡|₢|₦|₣|kn|₮|₩|₱|Kč|₯|₰|KD|د\\.ك|د\\.إ|د\\.ت|د\\.م";
  const currencyCodes = "AED|AFN|ALL|AMD|ANG|AOA|ARS|AUD|AWG|AZN|BAM|BBD|BDT|BGN|BHD|BIF|BMD|BND|BOB|BRL|BSD|BTN|BWP|BYN|BZD|CAD|CDF|CHF|CLP|CNY|COP|CRC|CUP|CVE|CZK|DJF|DKK|DOP|DZD|EGP|ERN|ETB|EUR|FJD|FKP|GBP|GEL|GHS|GIP|GMD|GNF|GTQ|GYD|HKD|HNL|HRK|HTG|HUF|IDR|ILS|INR|IQD|IRR|ISK|JMD|JOD|JPY|KES|KGS|KHR|KMF|KPW|KRW|KWD|KYD|KZT|LAK|LBP|LKR|LRD|LSL|LYD|MAD|MDL|MGA|MKD|MMK|MNT|MOP|MRO|MRU|MUR|MVR|MWK|MXN|MYR|MZN|NAD|NGN|NIO|NOK|NPR|NZD|OMR|PAB|PEN|PGK|PHP|PKR|PLN|PYG|QAR|RON|RSD|RUB|RWF|SAR|SBD|SCR|SDG|SEK|SGD|SHP|SLL|SOS|SRD|SSP|STD|STN|SVC|SYP|SZL|THB|TJS|TMT|TND|TOP|TRY|TTD|TVD|TWD|TZS|UAH|UGX|USD|UYU|UZS|VES|VND|VUV|WST|XAF|XCD|XDR|XOF|XPF|YER|ZAR|ZMW";
  const currencyFullNames = [  "United States Dollar", "Euro", "Japanese Yen", "British Pound", "Australian Dollar", "Canadian Dollar", "Swiss Franc", "Chinese Yuan Renminbi", "Swedish Krona", "New Zealand Dollar", "Mexican Peso", "Singapore Dollar", "Hong Kong Dollar", "Norwegian Krone", "South Korean Won", "Turkish Lira", "Indian Rupee", "Russian Ruble", "Brazilian Real", "South African Rand",
  "Danish Krone", "Polish Zloty", "Israeli New Shekel", "Indonesian Rupiah",
  "Thai Baht", "Saudi Riyal", "Malaysian Ringgit", "Hungarian Forint",
  "Czech Koruna", "Icelandic Krona", "Croatian Kuna", "Romanian Leu",
  "Bulgarian Lev", "United Arab Emirates Dirham", "Philippine Peso", "Egyptian Pound",
  "Chilean Peso", "Nigerian Naira", "Colombian Peso", "Bangladeshi Taka",
  "Peruvian Sol", "Moroccan Dirham", "Argentine Peso", "Vietnamese Dong",
  "Iraqi Dinar", "Algerian Dinar", "Sri Lankan Rupee", "Pakistani Rupee",
  "Kazakhstani Tenge", "Qatari Riyal"]; 

  const amountPattern = "(\\d{1,3}(?:[.,]\\d{3})*(?:[.,]\\d{2})?)";
  const currencyPattern = `(${currencySymbols}|${currencyCodes}|${currencyFullNames.join("|")})`;

  const patterns = [
    new RegExp(`${currencyPattern}\\s?${amountPattern}`, "gi"),
    new RegExp(`${amountPattern}\\s?${currencyPattern}`, "gi")
  ];

  const amountsMap = new Map();
  patterns.forEach(pattern => {
    let match;
    while ((match = pattern.exec(text)) !== null) {
      if (!amountsMap.has(match[0])) {
        amountsMap.set(match[0], {
          type: "Amount",
          text: match[0],
          indices: [match.index]
        });
      } else {
        const entry = amountsMap.get(match[0]);
        entry.indices.push(match.index);
      }
    }
  });

  return Array.from(amountsMap.values());
};

const fetchAmounts = async () => {
  setLoading(true);
  setError("");

  try {
    const body = await Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();
      return body.text;
    });

    const detectedAmounts = detectAmounts(body); // Detect amounts
    setAmountsData(detectedAmounts); // Update the amountsData state

  } catch (error) {
    setError(`Failed to process amounts: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

const onAmountClick = async (item, index) => {
  toggleDateAmountPopover(index, item, setCurrentType);
  await navigateToInstance(item.text, 0);
};

const navigateToInstance = async (text, index) => {
  await Word.run(async (context) => {
    const searchResults = context.document.body.search(text, { matchCase: true });
    context.load(searchResults, 'items');
    await context.sync();

    if (searchResults.items.length > index) {
      searchResults.items[index].select('Select');
      await context.sync();
    } else {
      console.error("Instance not found.");
    }
  }).catch(error => {
    console.error("Error in navigateToInstance: " + error);
  });
};

const dismissAmount = (amountText) => {
  setAmountsData(amountsData.filter(amount => amount.text !== amountText));
};

const detectPlaceholders = async (context, text) => {
  const body = context.document.body;
  const placeholderPatterns = [
    /\[[^\[\]]*\]/g,                  // Matches: [PLACEHOLDER] and []
    /\{\{\s*[^\{\}]*\s*\}\}/g,        // Matches: {{PLACEHOLDER}}, {{ }}, and {{}}
    /{[^{}]*}/g,                      // Matches: {PLACEHOLDER} and {}
    /__[A-Z_]*__/g,                   // Matches: __PLACEHOLDER__ and ____
    /<[A-Z]*>/g,                      // Matches: <PLACEHOLDER> and <>
    /\$[A-Za-z_][A-Za-z0-9_]*\b/g,    // Matches variable notation: $variableName
    /_{3,}/g,                         // Matches pure underlines (3 or more underscores), avoiding overlaps
    /\[\[[^\[\]]*\]\]/g,              // Matches: [[PLACEHOLDER]] and [[]]
  ];

  const encounteredPlaceholders = new Set(); // To store encountered placeholders
  let placeholders = [];

  placeholderPatterns.forEach(pattern => {
    let match;
    while ((match = pattern.exec(text)) !== null) {
      const placeholderText = match[0];
      const startIndex = match.index;
      const length = match[0].length;

      // Check if this placeholder is already encountered
      if (!encounteredPlaceholders.has(placeholderText)) {
        placeholders.push({
          type: "Placeholder",
          text: placeholderText,
          startIndex: startIndex,
          length: length
        });
        encounteredPlaceholders.add(placeholderText); // Add to set
      }
    }
  });

  return placeholders;
};

const fetchPlaceholders = async () => {
  setLoading(true);
  setError("");

  try {
    await Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();

      const text = body.text;
      const detectedPlaceholders = await detectPlaceholders(context, text);
      setPlaceholdersData(detectedPlaceholders);
    });
  } catch (error) {
    setError(`Failed to process placeholders: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

const dismissPlaceholder = (placeholderText) => {
  setPlaceholdersData(placeholdersData.filter(placeholder => placeholder.text !== placeholderText));
};

const refreshAll = async () => {
  setLoading(true);
  setError("");

  try {
    // Call all your fetching functions
    await fetchCrossReferences();
    await fetchDefinitions();
    await fetchDates();
    await fetchAmounts();
    await fetchPlaceholders();
  } catch (error) {
    // You can handle any errors here
    setError(`Failed to refresh data: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

const renderDefinitions = () => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', maxHeight: '226px', overflowY: 'auto' }}>

      {definitionsData.map((definition, index) => (
        <div key={index} style={boxItemStyle}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: '12px'}}>
            <div>
              <b>{definition.text}</b>
            </div>
            <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
              <div>
              <Button 
                  appearance="subtle"
                  size="small"
                  icon={<Delete12Regular />} // Replace with an appropriate icon
                  onClick={() => dismissDefinition(definition.text)}
                  style={{borderRadius:'10px'}}
              >
                </Button>
              </div>
              <div>
                <Popover open={openPopovers[index]}>
                  <PopoverTrigger disableButtonEnhancement>
                    <Button icon={<ArrowForward16Regular />} size="small" appearance="primary" onClick={() => togglePopover(index, 'definitions')} style={{ fontWeight: 'bold', borderRadius: '10px', minWidth:'40px' }} ></Button>
                  </PopoverTrigger>

                  <PopoverSurface tabIndex={-1} style={{padding: '5px'}}>
                    <Button size="small" icon={<ChevronUp16Regular />} appearance="subtle" onClick={() => findPrevious(definition.text, 'definitions')}></Button>
                      {renderCounter()}
                    <Button size="small" icon={<ChevronDown16Regular />} appearance="subtle" onClick={() => findNext(definition.text, 'definitions')}></Button>
                  </PopoverSurface>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  );
};
const numberOfDefinitions = definitionsData.length || 0;

const renderReferences = () => {

  if (!Array.isArray(referencesData) || referencesData.length === 0) {
    console.error("Invalid or empty referencesData");
    return <p>No references available.</p>;
  }

  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', maxHeight: '226px', overflowY: 'auto'}}>
      {referencesData.map((item, index) => (
        <div key={index} style={boxItemStyle}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontSize: '12px'}}>
            <div>
              <b>{item.text}</b>
            </div>
            <div>
              <Popover open={openPopovers[index]}>
                <PopoverTrigger disableButtonEnhancement>
                  <Button icon={<ArrowForward16Regular />} size="small" appearance="primary" onClick={() => togglePopover(index, 'references')} style={{ fontWeight: 'bold', borderRadius: '10px', minWidth: '40px' }} ></Button>
                </PopoverTrigger>
                <PopoverSurface tabIndex={-1} style={{padding: '5px'}}>
                  <Button size="small" icon={<ChevronUp16Regular />} appearance="subtle" onClick={() => findPrevious(item.text, 'references')}></Button>
                    {renderCounter()}
                  <Button size="small" icon={<ChevronDown16Regular />} appearance="subtle" onClick={() => findNext(item.text, 'references')}></Button>
                </PopoverSurface>
              </Popover>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const numberOfReferences = () => {
  return Array.isArray(referencesData) ? referencesData.length : 0;
};


const renderDates = () => {

  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', maxHeight: '226px', overflowY: 'auto' }}>
      {datesData.map((item, index) => {

        return (
          <div key={index} style={boxItemStyle}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <b>{item.text}</b>
                {item.indices.length > 1 ? (
                <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                  <div>
                  <Button 
                      appearance="subtle"
                      size="small"
                      icon={<Delete12Regular />} // Replace with an appropriate icon
                      onClick={() => dismissDate(item.text)}
                      style={{borderRadius:'10px'}}
                  >
                    </Button>
                  </div>
                  <div>
                  <Popover open={openPopovers[index]}>
                    <PopoverTrigger disableButtonEnhancement>
                      <Button 
                        size="small" 
                        appearance="primary" 
                        onClick={() => onDateClick(index, item)}
                        style={{ fontWeight: 'bold', borderRadius: '10px', minWidth:'40px' }}
                      >
                        {item.indices.length}
                      </Button>
                    </PopoverTrigger>
                    <PopoverSurface tabIndex={-1} style={{padding: '5px'}}>
                      <Button size="small" icon={<ChevronUp16Regular />} appearance="subtle" onClick={() => findPrevious(item.text, 'dates')}/>
                        {`${currentIndex + 1} of ${item.indices.length}`}
                      <Button size="small" icon={<ChevronDown16Regular />} appearance="subtle" onClick={() => findNext(item.text, 'dates')}/>
                    </PopoverSurface>
                  </Popover>
                  </div>
                  </div>
                ) : (
                  <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <div>
                    <Button 
                        appearance="subtle"
                        size="small"
                        icon={<Delete12Regular />} // Replace with an appropriate icon
                        onClick={() => dismissDate(item.text)}
                        style={{borderRadius:'10px'}}
                    >
                      </Button>
                    </div>
                    <div>
                    <Button 
                      size="small" 
                      appearance="primary" 
                      onClick={() => navigateToInstance(item.text, 0)}
                      style={{ fontWeight: 'bold', borderRadius: '10px', minWidth:'40px' }}
                    >1</Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
const numberOfDates = datesData.length || 0;

const renderAmounts = () => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', maxHeight: '226px', overflowY: 'auto' }}>
      {amountsData.map((item, index) => {
        return (
          <div key={index} style={boxItemStyle}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <b>{item.text}</b>
                {item.indices.length > 1 ? (
                <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <div>
                    <Button 
                        appearance="subtle"
                        size="small"
                        icon={<Delete12Regular />} // Replace with an appropriate icon
                        onClick={() => dismissAmount(item.text)}
                        style={{borderRadius:'10px'}}
                    >
                      </Button>
                    </div>
                    <div>
                    <Popover open={openPopovers[index]}>
                      <PopoverTrigger disableButtonEnhancement>
                        <Button 
                          size="small" 
                          appearance="primary" 
                          onClick={() => onAmountClick(index, item)}
                          style={{ fontWeight: 'bold', borderRadius: '10px', minWidth:'24px' }}
                        >
                          {item.indices.length} 
                        </Button>
                      </PopoverTrigger>
                      <PopoverSurface tabIndex={-1} style={{padding: '5px'}}>
                        <Button size="small" icon={<ChevronUp16Regular />} appearance="subtle" onClick={() => findPrevious(item.text, 'amounts')}/>
                          {`${currentIndex + 1} of ${item.indices.length}`}
                        <Button size="small" icon={<ChevronDown16Regular />} appearance="subtle" onClick={() => findNext(item.text, 'amounts')}/>
                      </PopoverSurface>
                    </Popover>
                    </div>
                  </div>
                ) : (
                  <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <div>
                    <Button 
                        appearance="subtle"
                        size="small"
                        icon={<Delete12Regular />} // Replace with an appropriate icon
                        onClick={() => dismissAmount(item.text)}
                        style={{borderRadius:'10px'}}
                    >
                      </Button>
                    </div>
                    <div>
                  <Button 
                    size="small" 
                    appearance="primary" 
                    onClick={() => navigateToInstance(item.text, 0)}
                    style={{ fontWeight: 'bold', borderRadius: '10px', minWidth:'40px' }}
                  >1</Button>
                  </div>
                  </div>
                )}
              </div>

            </div>
          </div>
        );
      })}
    </div>
  );
};
const numberOfAmounts = amountsData.length || 0;

const renderPlaceholders = () => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', maxHeight: '226px', overflowY: 'auto' }}>
    {placeholdersData.map((item, index) => {
        return (
          <div key={index} style={boxItemStyle}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <b>{item.text}</b>
                    <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                      <div>
                      <Button 
                      appearance="subtle"
                      size="small"
                      icon={<Delete12Regular />} // Replace with an appropriate icon
                      onClick={() => dismissPlaceholder(item.text)}
                      style={{borderRadius:'10px'}}
                  >
                    </Button>
                    </div>
                    <div>
                      <Button icon={<ArrowForward16Regular />} size="small" appearance="primary" style={{ fontWeight: 'bold', borderRadius: '10px', minWidth:'40px' }}></Button>
                    </div>
                  </div>
                </div>
                  <div style={{ display: 'flex', flexDirection: 'column'}}>                
                    {/* <div>startIndex:{item.startIndex}</div>
                    <div>length:{item.length}</div> */}
                  </div>
              </div>
            </div>
          );
        })}
        </div>
       );
    };
    const numberOfPlaceholders = placeholdersData.length || 0;

const boxItemStyle: React.CSSProperties = {
  boxSizing: 'border-box', // This is a string, so it's fine
  width: 'calc(100% - 20px)', // Also a string
  fontSize: '12px',
  marginBottom: '12px',
  backgroundColor: 'white', 
  borderRadius: '12px',
  padding: '8px',
  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 1px',
  border: '1px solid #E3E3E3'
};


useEffect(() => {
  fetchCrossReferences();
}, []); // Empty dependency array ensures this runs only on component mount


useEffect(() => {
  fetchDefinitions();
}, []);

useEffect(() => {
  fetchDates();
}, []);

useEffect(() => {
  fetchAmounts();
}, []);

useEffect(() => {
  fetchPlaceholders();
}, []);

  return (
    <>
    <Container>
      {showDisclaimer && (
      <div
        style={{
          backgroundColor: "#012040",
          color: "white",
          padding: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
          borderRadius: "8px",
          marginTop: "12px", 
          marginBottom: "16px" 
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ height: '24px', width: '24px' }}>
          <InformationCircleIcon style={{ height: "24px", width: '24px'}} aria-hidden="true" />
          </div>
          <div style={{ fontSize: '12px', display: "flex", flexDirection: "column", marginLeft: "8px" }}> 
            <span style={{ fontSize: '12px', fontWeight: "bold", marginBottom: "2px" }}>Don't miss a detail</span>
            <span style={{ lineHeight: '18px' }}>Our detections seek to be overinclusive to ensure thoroughness.</span>
          </div>
        </div>

        <button
            onClick={() => setShowDisclaimer(false)}         
            style={{
            background: "none",
            border: "none",
            color: "white",
            cursor: "pointer"
          }}
          aria-label="Dismiss disclaimer"
        >
          <XMarkIcon style={{ height: "24px" }} aria-hidden="true" />
        </button>
      </div>
    )}

      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: '12px'
      }}>
        {/* Definitions Button */}
        <div className={ `container-style ${showDefinitions ? 'open' : ''}` }>
          <button onClick={() => setShowDefinitions(!showDefinitions)} className={ `button-style ${showDefinitions ? 'open' : ''}` }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '12px',  padding: '8px 8px 4.5px 8px', borderRadius: '20px', backgroundColor: '#D7EBFF' }}>
              <BookGlobeFilled style={{ fontSize: '20px', color: '#474DA2' }} />
            </span>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Definitions
            </span>
            </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ color:'#2C671E', backgroundColor:'#DDF6C7', borderRadius:'5px', padding: '5px', marginRight: '10px', minWidth: '18px' }}>
            <span>
              <b>{numberOfDefinitions}</b>
            </span>
          </div>
          {showDefinitions ? <ChevronUp16Regular /> : <ChevronDown16Regular />}
        </div>
        </button>
          {showDefinitions && (
            <div style={{ padding: '10px 0px' }}>
              {renderDefinitions()}
            </div>
          )}
        </div>

        {/* Dates Button */}
        <div className={ `container-style ${showDates ? 'open' : ''}` }>
          <button onClick={() => setShowDates(!showDates)} className={ `button-style ${showDates ? 'open' : ''}` }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '12px',  padding: '8px 8px 4.5px 8px', borderRadius: '20px', backgroundColor: '#C9F5C9' }}>
              <CalendarDayFilled style={{ fontSize: '20px', color: '#1E714A' }} />
            </span>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Dates
            </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color:'#2C671E', backgroundColor:'#DDF6C7', borderRadius:'5px', padding: '5px', marginRight: '10px', minWidth: '18px' }}>
                <span>
                  <b>{numberOfDates}</b>
                </span>
              </div>
              {showDates ? <ChevronUp16Regular /> : <ChevronDown16Regular />}
            </div>
          </button>
          {showDates && (
            <div style={{ padding: '10px 0px' }}>
              {renderDates()}
            </div>
          )}
        </div>

        {/* Amounts Button */}
        <div className={ `container-style ${showAmounts ? 'open' : ''}` }>
          <button onClick={() => setShowAmounts(!showAmounts)} className={ `button-style ${showAmounts ? 'open' : ''}` }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '12px',  padding: '8px 8px 4.5px 8px', borderRadius: '20px', backgroundColor: '#F1F6F6' }}>
              <MoneyFilled style={{ fontSize: '20px', color: '#697387' }} />
            </span>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Amounts
            </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color:'#2C671E', backgroundColor:'#DDF6C7', borderRadius:'5px', padding: '5px', marginRight: '10px', minWidth: '18px' }}>
                <span>
                  <b>{numberOfAmounts}</b>
                </span>
              </div>
              {showAmounts ? <ChevronUp16Regular /> : <ChevronDown16Regular />}
            </div>
          </button>
          {showAmounts && (
            <div style={{ padding: '10px 0px' }}>
              {renderAmounts()}
            </div>
          )}
        </div>

        {/* References Button */}
        <div className={ `container-style ${showReferences ? 'open' : ''}` }>
          <button onClick={() => setShowReferences(!showReferences)} className={ `button-style ${showReferences ? 'open' : ''}` }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '12px',  padding: '8px 8px 4.5px 8px', borderRadius: '20px', backgroundColor: '#FDE3DE' }}>
              <BookNumberFilled style={{ fontSize: '20px', color: '#AF2A55' }} />
            </span>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            References
            </span>
            </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ color:'#2C671E', backgroundColor:'#DDF6C7', borderRadius:'5px', padding: '5px', marginRight: '10px', minWidth: '18px' }}>
              <span>
                <b>{numberOfReferences()}</b>
              </span>
            </div>
            {showReferences ? <ChevronUp16Regular /> : <ChevronDown16Regular />}
          </div>
        </button>
          {showReferences && (
            <div style={{ padding: '10px 0px' }}>
              {renderReferences()}
            </div>
          )}
        </div>

        {/* Placeholders Button
        <div className={ `container-style ${showPlaceholders ? 'open' : ''}` }>
          <button onClick={() => setShowPlaceholders(!showPlaceholders)} className={ `button-style ${showPlaceholders ? 'open' : ''}` }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '12px',  padding: '8px 8px 4.5px 8px', borderRadius: '20px', backgroundColor: '#e5c6f1' }}>
              <DocumentPageBreakFilled style={{ fontSize: '20px' }} />
            </span>
            <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Placeholders
            </span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color:'#2C671E', backgroundColor:'#DDF6C7', borderRadius:'5px', padding: '5px', marginRight: '10px', minWidth: '18px' }}>
                <span>
                  <b>{numberOfPlaceholders}</b>
                </span>
              </div>
              {showPlaceholders ? <ChevronUp16Regular /> : <ChevronDown16Regular />}
            </div>
          </button>
          {showPlaceholders && (
            <div style={{ padding: '10px 0px' }}>
              {renderPlaceholders()}
            </div>
          )}
        </div> */}


        <Button onClick={refreshAll} size="small" icon={<ArrowSync16Filled />} appearance="subtle">
          Refresh All
        </Button>

        {loading && <Field validationMessage="Loading..." validationState="none" validationMessageIcon={<SparkleFilled />}><ProgressBar /></Field>}
        {error && <MessageBar key="error" intent="error"><MessageBarBody>
          <MessageBarTitle>Error</MessageBarTitle>{error}</MessageBarBody></MessageBar>}

        </div>
      </Container>
    </>
  );
};
export default AdminTab;
